/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FunctionalComponent, h } from "preact";
import { logEvent } from "../../logging/eventProducer";
import ThumbnailsIsland from "@tempoplatform/tpds/_dist/components/thumbnails-island";
import { openURL } from "../../utils/openURL";

interface Props {
  cid: number;
  setSelectedProductIndex: (value: any) => void;
  isMuted: any;
  mute: () => void;
  setActiveComponent: (value: any) => void;
  setCurrentPage: (value: string) => void;
  data: any;
}

const BottomSection: FunctionalComponent<Props> = (props: Props) => {
  const { cid, setSelectedProductIndex, isMuted, mute, setActiveComponent, data, setCurrentPage } =
    props;

  const utmSuffix = `?utm_source=tempo&utm_medium=mobile&utm_campaign=${cid}&utm_content=${cid}`;

  let imagesData = [];

  if (data.ad_type === "redirect_to_webpage") {
    // requirements state this is up to four at the moment
    for (let i = 0; i < 4; i++) {
      const imageURL = data[`redirect_to_webpage_image_${i}`];
      const linkURL = data[`redirect_to_webpage_url_${i}`];
      if (imageURL && linkURL) {
        imagesData.push({
          url: imageURL, // "url" in imagesData means path to the image
          callBack: () => {
            logEvent(`CLICKED_PRODUCT_${i}`);
            if (!isMuted) {
              mute();
            }
            // wait for logEvent + mute to take effect before redirecting
            setTimeout(() => {
              openURL(`${linkURL}${utmSuffix}`);
            }, 200);
          },
        });
      }
    }
  } else {
    imagesData = data.campaign_products.map((cp, index) => {
      return {
        url: cp.product.image_urls[0],
        callBack: () => {
          setCurrentPage("product");
          logEvent(`CLICKED_PRODUCT_${index}`);
          setSelectedProductIndex(index);
          if (!isMuted) {
            mute();
          }
          setSelectedProductIndex(index);
          setActiveComponent(index);
        },
      };
    });
  }

  return (
    <ThumbnailsIsland
      backgroundColor={data.ad_style?.bottom_island?.background_color ?? "#96969621"}
      thumbnailsBackgroundColor={
        data.ad_style?.bottom_island?.thumbnails_background_color ?? "#ffffff21"
      }
      thumbnailsBorderRadius={data.ad_style?.bottom_island?.thumbnails_border_radius ?? 6}
      thumbnailsPadding={data.ad_style?.bottom_island?.thumbnails_padding ?? 0}
      removeEnclosingIsland={data.ad_style?.bottom_island?.remove_enclosing_island}
      items={imagesData}
    />
  );
};

export default BottomSection;
