import { FunctionalComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { ActiveComponent } from "../../../website/components/state";
import VideoSection from "../media/VideoSection";
import ImageSection from "../media/ImageSection";
import BrandInfo from "../brand/BrandInfo";
import BottomSection from "../product/BottomSection";
import { logEvent } from "../../logging/eventProducer";
import clsx from "clsx";
import { openURL } from "../../utils/openURL";

const infoIslandStyle = clsx(
  `w-auto`,
  `inline-flex`,
  `rounded-md p-2.5 px-3.5`,
  `absolute left-[8px] top-[8px] z-10`,
);

interface Props {
  data: any;
  cid: number;
  setActiveComponent: (c: ActiveComponent) => void;
  vidRef: any;
  setSelectedProductIndex: (i: number) => void;
  selectedProductIndex: number;
  mainRef: any;
  setCurrentPage: (value: string) => void;
  isMuted: boolean;
  mute: any;
}

const Main: FunctionalComponent<Props> = (props: Props) => {
  const { cid, data, setCurrentPage, setSelectedProductIndex, isMuted, mute } = props;
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [areImagesLoaded, setAreImagesLoaded] = useState<boolean>(false);

  const utmSuffix = `?utm_source=tempo&utm_medium=mobile&utm_campaign=${cid}&utm_content=${cid}`;

  useEffect(() => {
    data &&
      Promise.all(
        Array.from(document.images)
          .filter(img => !img.complete)
          .map(
            img =>
              new Promise(resolve => {
                img.onload = img.onerror = resolve;
              }),
          ),
      ).then(() => {
        setAreImagesLoaded(true);
        logEvent("TEMPO_IMAGES_LOADED");
      });
  }, []);

  useEffect(() => {
    if (isVideoLoaded && areImagesLoaded) {
      logEvent("TEMPO_ASSETS_LOADED");
    }
  }, [isVideoLoaded, areImagesLoaded]);

  const handleMainMediaClick = () => {
    if (data.ad_type === "redirect_to_webpage") {
      logEvent("CLICKED_MAIN_MEDIA");
      if (!isMuted) {
        mute();
      }
      // wait for logEvent + mute to take effect before redirecting
      setTimeout(() => {
        openURL(`${data.redirect_url}${utmSuffix}`);
      }, 200);
    }
  };

  return (
    <div className="main-viewport" style={styles.mainViewPort}>
      <div
        className={infoIslandStyle}
        style={{
          top: data.is_bottom_headline ? undefined : "8px",
          bottom: data.is_bottom_headline ? "8px" : undefined,
          background: data.ad_style?.top_island?.background_color ?? "rgba(156, 163, 175, 0.2)",
        }}
      >
        <BrandInfo data={data} />
      </div>
      <div
        style={styles.mainMediaWrapper}
        onClick={data.ad_type === "redirect_to_webpage" ? () => handleMainMediaClick() : null}
      >
        {data.video_url ? (
          <VideoSection
            data={data}
            vidRef={props.vidRef}
            setIsVideoLoaded={value => setIsVideoLoaded(value)}
          />
        ) : (
          <ImageSection image_url={data.image_url} />
        )}
      </div>
      <BottomSection
        cid={cid}
        data={data}
        setCurrentPage={setCurrentPage}
        setSelectedProductIndex={(value: any) => setSelectedProductIndex(value)}
        isMuted={isMuted}
        mute={mute}
        setActiveComponent={(value: any) => props.setActiveComponent(value)}
      />
    </div>
  );
};

const styles = {
  mainViewPort: {
    width: "100%",
    height: "100%",
  },
  mainMediaWrapper: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    zIndex: 1,
    overflow: "hidden",
  },
};

export default Main;
